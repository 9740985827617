import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-bottom": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      onDateChange: _vm.onDateChange,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置 ")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticClass: "tableCard",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titleseffect,
      handleClick: _vm.handleClickeffect
    }
  }), _vm.defaultIndex == 0 ? _c("a-upload", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:add"],
      expression: "['commodity:btn:add']"
    }],
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      name: "file",
      "before-upload": _vm.beforeUpload,
      showUploadList: false
    },
    on: {
      change: _vm.allImport
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      size: "large"
    }
  }, [_vm._v("批量导入")])], 1) : _vm._e(), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:add"],
      expression: "['commodity:btn:add']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.allSetup();
      }
    }
  }, [_vm._v("批量设置")]), _vm.defaultIndex == 2 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:shevelsup"],
      expression: "['commodity:btn:shevelsup']"
    }],
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handelClickAll(1);
      }
    }
  }, [_vm._v("批量上架")]) : _vm._e(), _vm.defaultIndex == 0 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:shevelsdown"],
      expression: "['commodity:btn:shevelsdown']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handelClickAll(2);
      }
    }
  }, [_vm._v("批量下架")]) : _vm._e(), _vm.defaultIndex == 1 || _vm.defaultIndex == 2 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:shevelsdown"],
      expression: "['commodity:btn:shevelsdown']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handelClickAll(3);
      }
    }
  }, [_vm._v("批量删除")]) : _vm._e(), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.newcolumns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectdChange,
        fixed: true
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "imgurls" ? _c("span", [record.imgurls ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.filterCoverImg(record.imgurls)
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.imgurls);
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "name" ? _c("span", [_c("span", [_vm._v(_vm._s(record.name.slice(0, 15)) + _vm._s(record.name.length > 15 ? "..." : ""))])]) : key == "status" ? _c("span", [_c("span", {
            class: [record.status == 2 ? "err" : ""]
          }, [_vm._v(_vm._s(record.status == 0 ? "上架" : record.status == 1 ? "下架" : record.status == 2 ? "异常" : "---"))])]) : key == "action" ? _c("span", [record.status == 0 ? _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:edit"],
              expression: "['brand:index:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleDown(record);
              }
            }
          }, [_vm._v("下架")]) : _vm._e(), record.status == 1 ? _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:delete"],
              expression: "['brand:index:delete']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleShelf(record);
              }
            }
          }, [_vm._v("上架")]) : _vm._e(), record.status == 2 ? _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:delete"],
              expression: "['brand:index:delete']"
            }],
            on: {
              click: function click($event) {
                return _vm.recover(record.id);
              }
            }
          }, [_vm._v("恢复")]) : _vm._e(), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:delete"],
              expression: "['brand:index:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDetail(record.id);
              }
            }
          }, [_vm._v("详情")])], 1), record.status == 0 ? _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:delete"],
              expression: "['brand:index:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleSetup(record);
              }
            }
          }, [_vm._v("编辑")])], 1) : _vm._e(), record.status != 0 ? _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:delete"],
              expression: "['brand:index:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDelete(record);
              }
            }
          }, [_vm._v("删除")])], 1) : _vm._e()]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "编辑",
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleSave,
      cancel: _vm.cancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [!_vm.isBatch ? _c("a-form-item", {
    attrs: {
      label: "内部货号"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.editorForm.skuNum))])]) : _vm._e(), !_vm.isBatch ? _c("a-form-item", {
    attrs: {
      label: "外部货号"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.editorForm.outSkuNum,
      callback: function callback($$v) {
        _vm.$set(_vm.editorForm, "outSkuNum", $$v);
      },
      expression: "editorForm.outSkuNum"
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "更新报价频率"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请输入",
      type: "number"
    },
    model: {
      value: _vm.editorForm.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.editorForm, "rate", $$v);
      },
      expression: "editorForm.rate"
    }
  }, _vm._l(_vm.getDataWithKey("rate").filter(function (item) {
    return item.id != "";
  }), function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1)]), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      title: _vm.title,
      loading: _vm.loading,
      isDelete: _vm.isDelete,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };